const library = "/bibliothek";
const workspace = "/gesetz";
const settings = "/einstellungen";

/**
 * For usage in links, use `withLocale` to prepend the current locale.
 */
export const routes = {
  library: {
    base: library,
    contents: `${library}/inhalte`,
    chronicle: `${library}/chronik`,
    laws: `${library}/gesetze`,
    channels: `${library}/inhalte/channels`,
  },
  workspace,
  settings: {
    base: settings,
    profile: `${settings}/profil`,
    password: `${settings}/passwort`,
    dataProtection: `${settings}/datenschutz`,
  },
  forgotPassword: "/forgot-password",
  privacyNotice: "/datenschutzhinweis",
  termsAndConditions: "/agb",
  imprint: "/impressum",
  faq: "/faq",
  intro: "/intro",
  vision: "/vision",
  team: "/team",
  subscribeChannel: "/subscribe-channel",
  joinUs: "/komm-ins-team",
  schemas: "/schemata",
  updates: "/updates",
  laws: "/laws",
} as const;
