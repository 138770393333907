import { createCookies } from "@vueuse/integrations/useCookies";
import type { PageContext } from "vike/types";

export function useCookies(
  dependencies: string[] | null | undefined,
  pageContext?: PageContext
) {
  // we can access cookies directly, which universal-cookie does
  if (!import.meta.env.SSR) {
    return createCookies()(dependencies);
  }

  const ctx = pageContext ?? usePageContext();

  return createCookies(ctx.request)(dependencies);
}

export function useCookie<T = unknown>(name: string) {
  return (pageContext?: PageContext) => {
    const cookies = useCookies([name], pageContext);

    return computed({
      get: () => cookies.get<T>(name),
      set: (val: T) =>
        cookies.set(name, val, {
          path: "/",
          sameSite: "none",
          secure: true,
          partitioned: true,
        }),
    });
  };
}
